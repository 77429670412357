<template>
  <st-title
    :navItems="[
      {
        name: $t('studio.business_bank_setting.title'),
        url: 'business-bank'
      },
      {
        name: $t('studio.business_bank_setting.list_name')
      }
    ]"
    :title="$t('studio.business_bank_setting.list_name')"
  />
  <div class="flex gap-24 mt-40">
    <div class="flex-1">
      <st-box>
        <st-table
          :paging="{ total, pageSize, show: true }"
          :data="list"
          :columns="columns"
          @pageChange="onPageChange"
        >
          <template #empty>
            <st-tr class="!border-b-0">
              <st-td colspan="5">
                <p
                  class="flex justify-center items-center h-full min-h-220 py-20 text-md leading-lg text-on-surface-elevation-4 text-center"
                >
                  {{ $t('studio.business_bank_setting.list_no_data') }}
                </p>
              </st-td>
            </st-tr>
          </template>
        </st-table>
      </st-box>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { fetchBusinessHistoriesApi } from '@/apis/business-bank-info.api';
import StBox from '@/components/common/st-box.vue';
import stTitle from '@/components/common/st-title.vue';
import StTable from '@/components/common/table/st-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import StTr from '@/components/common/table/st-tr.vue';
import { BUSINESS_BANK_STATUS, BUSINESS_TYPE } from '@/constants/business-bank.const';
import { DATE_TIME_FORMAT_WITH_DOT } from '@/constants/date-time.const';
import { useUserStore } from '@/stores/user.store';
import type { BusinessListContentModel } from '@/types/business-info/business-model.type';
import type { Column } from '@/types/table.type';
import { formatDateTime } from '@/utils/date.util';

const userStore = useUserStore();
const { selectedGroupId } = storeToRefs(userStore);
const { t } = useI18n();

const columns = ref<Column[]>([
  {
    title: 'studio.business_bank_setting.list_title1',
    width: '10rem',
    align: 'left',
    fontWeight: 'bold',
    render: (record: BusinessListContentModel) => {
      return record.companyStatus === BUSINESS_BANK_STATUS.APPROVE
        ? t('studio.business_bank_setting.list_stt_approve')
        : record.companyStatus === BUSINESS_BANK_STATUS.REJECT
          ? t('studio.business_bank_setting.list_stt_reject')
          : t('studio.business_bank_setting.list_stt_in_review');
    }
  },
  {
    title: 'studio.business_bank_setting.list_title2',
    width: '18rem',
    align: 'left',
    fontWeight: 'bold',
    render: (record: BusinessListContentModel) => {
      return record.businessType === BUSINESS_TYPE.PERSONAL
        ? t('studio.business_bank_setting.list_biz_type_indiv')
        : record.businessType === BUSINESS_TYPE.INDIVIDUAL_BUSINESS
          ? t('studio.business_bank_setting.list_biz_type_indiv_biz_owner')
          : t('studio.business_bank_setting.list_biz_type_business');
    }
  },
  {
    title: 'studio.business_bank_setting.list_title3',
    width: 'auto',
    align: 'left',
    fontWeight: 'bold',
    dataIndex: 'businessName'
  },
  {
    title: 'studio.business_bank_setting.list_title4',
    width: '16rem',
    align: 'left',
    fontWeight: 'normal',
    render: (record: BusinessListContentModel) =>
      formatDateTime(record.requestAt, DATE_TIME_FORMAT_WITH_DOT)
  },
  {
    title: 'studio.business_bank_setting.list_title5',
    width: '16rem',
    align: 'left',
    fontWeight: 'normal',
    render: (record: BusinessListContentModel) =>
      formatDateTime(record.reviewAt, DATE_TIME_FORMAT_WITH_DOT)
  }
]);

const list = ref<BusinessListContentModel[]>([]);
const total = ref<number>(0);
const currentPage = ref<number>(1);
const pageSize = 50;

const onPageChange = (page: number) => {
  currentPage.value = page;
};

const fetchList = async (): Promise<any> => {
  const res = await fetchBusinessHistoriesApi(selectedGroupId.value || '');
  if (res) {
    list.value = res.contents;
    total.value = res.totalElements;
  }
};

const init = async () => {
  await fetchList();
};
init();
</script>
